@import "../../App.css";


.info-display-container {
    padding: 10px;
    margin: 10px;

}
  
.renter-info {
  display: flex;
  flex-direction: column;

  div {
      margin-left: 10px;
  }
}

.renter-info .field-heading {
  font-weight: 650;
}