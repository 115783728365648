.payment-container {
    padding: 10px;
    margin: 10px;
}

.google-pay {
    margin: 10px 0;
}

.back-to-info-btn {
    margin-top: 20px;
}

#payment-title {
    font-weight: bold;
    font-size: 26px;
}

.payment-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 650;

    .deposit {
        
        span {
            background-color: rgb(230, 159, 159);
        }
    }

}

#terms-checkbox {
    margin: 0.3em;

    font-size: 17px;
    font-weight: 700;
}



.payment-form {
    margin: 0 auto;
    max-width: 700px;
    min-height: 200px;
    position: relative;
}

.disable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7); 
    /* cursor: not-allowed; */
    z-index: 30;
}


.shake {
    animation: shake 0.5s;
    /* animation-iteration-count: infinite; */
}
  
@keyframes shake {
    /* 0% { transform: rotate(0deg); }
    25% { transform: rotate(2deg); }
    50% { transform: rotate(-2deg); }
    75% { transform: rotate(1deg); }
    100% { transform: rotate(0deg); } */


    0% { transform: translate(0px); }
    25% { transform: translate(5px); }
    50% { transform: translate(-5px); }
    75% { transform: translate(2px); }
    100% { transform: translate(0px); }
}



