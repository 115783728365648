.discount-container {
    width: 100%;
    font-weight: 500;
    display: block;
}

.discount-form {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .discount-group {
        flex-grow: 3;
        width: 100%;

        input {
            border-radius: 30px;
            height: 40px;
        }
    }


    button {
        margin-left: 5px;
        border-radius: 30px;
        flex-grow: 1;
    }
}


.invalid {
    color: red;
}