.nav-bar {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 5;
}

#continue-btn {
    background-color: #008080;
    width: 100%;
    font-weight: bold;
  }

