@import "../../App.css";


.price-display-container {
    display: block;
    padding: 10px;
    margin: 10px;
    text-align: center;
}
.cost-title {
    font-weight: bolder;
}

.bold-text {
    font-size: 18px;
    font-weight: 500;
}

.cost-details {
    text-align: left;

    .unit-description {
        display: flex;
        
        @media screen and (min-width: 400px) {
            flex-direction: row;
        }

        /* Styles for screens smaller than 600px */
        @media screen and (max-width: 400px) {
            flex-direction: column;
            justify-content: left;
        }

        img {
            height: 100%;
            max-width: 125px;
            margin: auto 0;
        }

        div {
            align-content: center;
            padding: 5px;
            /* font-weight: bold; */
        }

    }

    .cost-display-contianer {
        font-weight: bold;

        .cost-note {
            font-size: small;
            color: brown;
        }
    }

    .price-item {
        text-align: right;
        font-weight: 550;
    }

    #total-price {
        font-weight: 750;
    }
}

