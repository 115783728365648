.progress-bar-container {
    border: 5px solid #f3f3f3;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    text-transform: uppercase;
    width: 100vw;
    height: 50px;
    background-color: #f3f3f3;
    position: fixed;
    top: 0;
    z-index: 5;
    min-width: 360px;
}

.progress-bar-step {
    width: 100%;
    flex-grow: 1;
    text-align: center;
    align-content: center;
    cursor: pointer;
    border: 2px solid #f3f3f3;
    padding: 5px;
}

.current {
    font-weight: 900;
    text-decoration: underline;
    background-color: var(--dark-blue) !;
}

.complete {
    background-color: var(--light-blue);
}

