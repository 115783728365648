.unit-tile-image {
  display: block;

  img {
    position: relative;
    z-index: 0;
    width: 100%;
    height: auto;
    max-height: 160px;
    object-fit: cover;
  }

  p {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    padding: 1em;
    margin: 0;
    border-bottom-left-radius: 1em;
    width: fit-content;
  }
}

.unit-tile-name {
  font-size: 1.5em;
  margin: 0;
}

.unit-tile-info {
  margin: 0;
}

