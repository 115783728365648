.form-container {
    padding: 10px;
    margin: 10px;
}

.form-group {
    font-weight: 550;
}

.renter-form {
    display: block;
    margin: 0px auto;
    width: 100%;
}

.info-sbmt-btn {
    background-color: var(--main-bg-color-green);
}

.nav-buttons {
    width: 100%;
    display: inline-flex;

    button {
        margin: 10px;
        width: 100%;
    }
}

.renter-title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
}

.renter-instructions {
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.error-text {
    font-size: 12px;
    color: brown;
}