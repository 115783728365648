.calendar-container {
  display: block;
  padding: 10px;
  margin: 10px;
}

.react-calendar {
  min-width: 300px;
  background: white;
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}


.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  color: black;
}

.react-calendar__navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 44px;
  margin-bottom: 1em;

  button {
    flex-grow: 0;
    width: fit-content;
    background-color: transparent;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    font-size: large;
    font-weight: bolder;
  }
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation button {
  color: black;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: 400;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: 400;
  background-color: transparent;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 18px;
  font-weight: 200;
}

.react-calendar__tile:focus {
  outline: none;
}

.calendar-container .unavailable {
  background-color: var(--light-grey);
  text-decoration: line-through solid black 2px;
}

.calendar-container .selected {
  background-color: var(--light-blue);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 3px 10px rgba(0, 0, 0, 0.1);

}

.calendar-container .available {
  background-color: #ffffff;
}

@media(hover: hover) and (pointer: fine) {
  .react-calendar__tile:enabled:hover {
    /* filter:brightness(90%); */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }  

  .react-calendar__navigation button:enabled:hover {
    background-color: #f1f1f1;
  }
}

.react-calendar__tile--now abbr{
    font-weight: 900;
}



.calendar-legend {
  max-width: 100%;
  margin: 10px auto;
  display: flex;
  text-align: center;
  justify-content: space-around;

  .item {
    margin: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    font-size: small;

    .color {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30px;
      height: 20px;
      margin: 0 auto;
      color: transparent;
      border-radius: 0px;
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center
    }
  }
}

.calendar-instructions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 10px;

  div {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  button {
    margin: 0;
  }

  p {
    margin: 0;
  }

  button.hidden {
    visibility: hidden;
  }


}