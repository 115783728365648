.maintenance-container {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #d9534f;
  }
  
  p {
    color: #333;
  }
  