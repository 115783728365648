.title-container {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    margin-bottom: 10px;

    .title {
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        text-align: left;
    }
    button {
        width: 70px;
    }

}

.hidden {
    visibility: hidden;
}