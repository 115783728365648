.spinner {
  margin: 0 auto;
}

.continue-btn-container {
  position:sticky;
  bottom: 0;
  width: 100vw;
  margin: 0;
}

.clear-btn {
  width: 100%;
  margin-top: 10px;
}

@media(hover: hover) {
  button:hover:not(:disabled) {
    filter: brightness(80%);
  }
}

.booking-container {
  max-width: 1000px;
  margin: 60px auto;
}

.section-heading {
  font-weight: bold;
  font-size: 18px;
}

.main-border {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.secondary-border {
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

button {
  color: black;
  background-color: buttonface;
}

.keep-loading-instuctions {
  text-align: center;
}