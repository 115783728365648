.complete-container {
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--light-green);
    text-align: center;

    button {
        width: fit-content;
        margin: 10px;
    }

}

.title {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
}