@import "../../App.css";


.booking-date-row:hover {
    background-color: buttonface;
}

.booking-date-row.delete {
    background-color: brown;
}

.booking-date-row.add {
    text-align: center;
    color: green;
}

.expanded-content {
    /* background-color: rgb(243, 243, 243); */
    padding: 5px;
}