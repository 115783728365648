@import "../../App.css";

.selectable-units {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.date-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border: 5px solid var(--light-blue);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.date-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px;
  background-color: var(--light-blue);
}

.date-options {
  text-align: left;
  font-weight: bolder;
}

.btn-container {
  width: fit-content;
}

.tile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
}

.unit-tile-container {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.unit-tile-container.selected {
  box-shadow: 0px 4px 8px rgba(66, 66, 66, 0.8);
  background-color: #f1f1f1a9;
}

.checkbox-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* Ensure checkbox is on top of the tile */

  input {
    margin: 5px;
    width: 30px;
    height: 30px;
  }

  div {
    margin: 10px auto;
    font-weight: bolder;
  }
}

.slot-dropdown {

  width: 100%; /* Adjust as needed */
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #fff;
}

.slot-dropdown option {
  padding: 5px;
  font-size: 14px;
  background-color: #fff;
}

/* .slot-list {
  width: 100%; 
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .slot-list-item {
    width: 100%;
    height: 100%;

    &.selected {
      background-color: var(--light-blue);
    }
  }
} */
